import { render, staticRenderFns } from "./policyList.vue?vue&type=template&id=bed9a6c2&scoped=true&"
import script from "./policyList.vue?vue&type=script&lang=js&"
export * from "./policyList.vue?vue&type=script&lang=js&"
import style0 from "./policyList.vue?vue&type=style&index=0&id=bed9a6c2&scoped=true&lang=css&"
import style1 from "./policyList.vue?vue&type=style&index=1&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.9.7@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bed9a6c2",
  null
  
)

export default component.exports
<template>
    <el-container class="jumpPolicy">
        <el-header>
            <div class="page_title1">
                找项目：科技企业集聚追踪系统（精准招商）
            </div>
            <div class="page_title2">
                电子政策汇编系统-{{policy_proName?policy_proName:''}}适用政策
            </div>
        </el-header>
        <el-main >
            <el-button  class="go_back" type="text" @click="goBack"> <i class="el-icon-arrow-left"></i>返回</el-button>
            <el-table border :data="tableData" style="width: 100%;">
                <el-table-column label="政策标题" align="center" prop='title' min-width="120" show-overflow-tooltip></el-table-column>
                <el-table-column label="领域（行业）" align="center"  min-width="100">
                    <template slot-scope="scope">
                        <span style="margin:3px;" v-for="(item,index) in scope.row.industry" :key="index">{{item.title}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="适用地域" align="center"  min-width="100">
                    <template slot-scope="scope">
                        <span style="margin:3px;" v-for="(item,index) in scope.row.area" :key="index">{{item.name}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="体系" align="center"  min-width="100">
                    <template slot-scope="scope">
                        <span style="margin:3px;" v-for="(item,index) in scope.row.system" :key="index">{{item.title}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="类型" align="center"  min-width="100">
                    <template slot-scope="scope">
                        <span style="margin:3px;" v-for="(item,index) in scope.row.type" :key="index">{{item.title}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="发布单位" align="center" prop='title' min-width="100" show-overflow-tooltip></el-table-column>
                <el-table-column label="匹配度" align="center" prop='title' min-width="140" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span style="color: #409EFF;">{{scope.row.weight?scope.row.weight:0}}%</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center"  min-width="100">
                    <template slot-scope="scope">
                        <el-button size="small" type="text" @click="showDetails(scope.row)">详情</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <Pages  @currentPageChange="pageChange"
                    :pageSize="limit"
                    :page="meta.current_page"
                    :total="meta.total"
                    v-if="tableData.length>0"
                    style="margin:20px auto;text-align: center;"
            />
        </el-main>
        <el-dialog  :visible.sync="detailsVisible" width="80%">
            <div class="container">
                <div class="policyCompilationTitle">电子政策汇编系统</div>
                <el-breadcrumb separator-class="el-icon-arrow-right" class="navbar">
                    <el-breadcrumb-item>政策汇编</el-breadcrumb-item>
                    <el-breadcrumb-item>新增政策素材</el-breadcrumb-item>
                </el-breadcrumb>

                <el-row :gutter="20">
                    <el-col :span="12" style="padding:0">
                        <div class="item">
                            <label class="label required">政策标题：</label>
                            <div class="formControl">
                                <el-input v-model="title" disabled size="mini" maxlength="200"></el-input>
                            </div>
                        </div>

                        <div class="item">
                            <label class="label required">一句话描述：</label>
                            <div class="formControl">
                                <el-input v-model="description" disabled size="mini" maxlength="500"></el-input>
                            </div>
                        </div>

                        <div class="item" style="align-items: flex-start;">
                            <label class="label required">发布单位：</label>
                            <div class="itemBorder formControl">
                                <div class="controls">
                                    <label for="" class="controlslabel">全称：</label>
                                    <el-input v-model="publisher_full_name" disabled size="mini" maxlength="100"></el-input>
                                </div>
                                <div class="controls">
                                    <label for="" class="controlslabel">简称：</label>
                                    <el-input v-model="publisher_simple_name" disabled size="mini" maxlength="100"></el-input>
                                </div>
                            </div>
                        </div>

                        <div class="item">
                            <label for="" class="label required">适用地域：</label>
                            <div class="formControl">
                                <el-radio-group v-model="apply_to_area_type">
                                    <el-radio :label="item.id" v-for="item in areaType" :key="item.id" disabled @change="diyuChange">{{item.title}}</el-radio>
                                </el-radio-group>
                            </div>
                        </div>

                        <div class="item" v-if="apply_to_area_type == 2">
                            <label for="" class="label"></label>
                            <div class="formControl">
                                <el-cascader :options="area" :props="props" disabled size="mini" :show-all-levels="false" v-model="areas"></el-cascader>
                            </div>
                        </div>

                        <div class="item" v-if="apply_to_area_type == 3">
                            <label for="" class="label"></label>
                            <div class="formControl">
                                <el-cascader :options="yuanqu" disabled :props="props2" size="mini" v-model="yq"></el-cascader>
                            </div>
                        </div>

                        <div class="item guimo" style="align-items: flex-start;">
                            <label for="" class="label required">规模：</label>
                            <div class="itemBorder formControl">
                                <div style="display: flex;">
                                    <div class="controls">
                                        <label for="" class="controlslabel">规模：</label>
                                        <el-select v-model="scale_id" placeholder="规模编号" size="mini" disabled style="width: 120px;">
                                            <el-option v-for="item in guimo" :key="item.id" :label="item.title" :value="item.id"></el-option>
                                        </el-select>
                                    </div>
                                    <div class="controls">
                                        <label for="" class="controlslabel">行业：</label>
                                        <el-select v-model="scale_industry_id" placeholder="行业编号" size="mini" disabled style="width: 120px;">
                                            <el-option v-for="item in hangye" :key="item.id" :label="item.title" :value="item.id"></el-option>
                                        </el-select>
                                    </div>
                                </div>
                                <div style="display: flex;">
                                    <div class="controls">
                                        <label for="" class="controlslabel">从业人数：</label>
                                        <el-input v-model="scale_engage_number" disabled placeholder="从业人数" size="mini" style="width: 120px;"></el-input>
                                    </div>
                                    <div class="controls">
                                        <label for="" class="controlslabel">营业收入：</label>
                                        <el-input v-model="scale_revenue" disabled placeholder="营业收入" size="mini" style="width: 160px;">
                                            <template slot="append">万元</template>
                                        </el-input>
                                    </div>
                                </div>
                                <div style="display: flex;">
                                    <div class="controls">
                                        <label for="" class="controlslabel">资产总额：</label>
                                        <el-input  v-model="total_assets" disabled placeholder="资产总额" size="mini" style="width: 160px;">
                                            <template slot="append">万元</template>
                                        </el-input>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="item" style="align-items: flex-start;">
                            <label for="" class="label required">类型：</label>
                            <div class="itemBorder formControl" style="display: flex;">
                                <el-checkbox-group v-model="types">
                                    <el-checkbox style="margin:8px" disabled v-for="item in materialType" :key="item.id" :label="item.id">{{item.title}}</el-checkbox>
<!--                                    <el-button size="mini" style="margin-left: 8px;" @click="customType">自定义</el-button>-->
                                </el-checkbox-group>
                            </div>
                        </div>

                        <div class="item">
                            <label for="" class="label required">文章类型：</label>
                            <div class="formControl">
                                <el-select v-model="booksType" placeholder="请选择" size="mini" @change="changeArtical">
                                    <!-- <el-option value="插入链接">插入链接</el-option> -->
                                    <el-option value="自定义">自定义</el-option>
                                </el-select>
                            </div>
                        </div>
                        <div class="item" v-if="booksTypeActive">
                            <label class="label"></label>
                            <div class="formControl">
                                <el-input v-model="article_link_url" disabled placeholder="请输入链接地址" size="mini" maxlength="100"></el-input>
                            </div>
                        </div>

                        <div class="item" style="align-items: flex-start;" v-else>
                            <label for="" class="label"></label>
                            <div class="formControl" style="height: auto">
<!--                                <Editor @getContent="getEditorContent" :value="content"></Editor>-->
                                <div v-html="content"></div>
                            </div>
                        </div>

                    </el-col>
                    <el-col :span="12" style="padding-left:20px">
                        <div class="item" style="align-items: flex-start;">
                            <label for="" class="label required">政策主图：</label>
                            <div class="formControl" style="display: flex;align-items: flex-end;">
                                <el-upload class="avatar-uploader" disabled accept="image/jpeg,image/jpg,image/png" :action="action" :show-file-list="false"
                                           :on-success="handleAvatarSuccess" :before-upload="handleChange">
                                    <img v-if="img" :src="img" class="avatar">
                                    <div class="noAvatar" v-else>
                                        <i class="el-icon-upload avatar-uploader-icon"></i>
                                        <span>政策图片</span>
                                    </div>
                                </el-upload>
<!--                                <span style="color: red; margin-left: 15px;margin-bottom: 15px;font-size: 14px;">图片格式支持：jpg、jepg、png，大小不超过4M</span>-->
                            </div>
                        </div>

                        <div class="item">
                            <label for="" class="label required">领域(行业)：</label>
                            <div class="formControl">
                                <el-radio-group v-model="industry_scope" @change="industryScopeChange">
                                    <el-radio :label="item.id" disabled v-for="item in industryScopeType" :key="item.id">{{item.title}}</el-radio>
                                </el-radio-group>
                            </div>
                        </div>

                        <div class="item">
                            <label for="" class="label"></label>
                            <div class="formControl">
                                <el-cascader :options="lingyu" :props="props3" disabled size="mini" :show-all-levels="false" v-model="industry_scope_array"></el-cascader>
                            </div>
                        </div>
                        <div class="item" style="align-items: flex-start;">
                            <label for="" class="label required">体系：</label>
                            <div class="itemBorder formControl" style="display: flex;">
                                <el-checkbox-group v-model="systems">
                                    <el-checkbox style="margin:8px" disabled v-for="item in tixi" :key="item.id" :label="item.id">{{item.title}}</el-checkbox>
<!--                                    <el-button size="mini" style="margin-left: 8px;" @click="customSystems">自定义</el-button>-->
                                </el-checkbox-group>
                            </div>
                        </div>

                        <div class="item">
                            <label for="" class="label required">政策状态：</label>
                            <div class="formControl">
                                <el-radio-group v-model="policy_status">
                                    <el-radio :label="item.id" disabled v-for="item in policyStatusType" :key="item.id">{{item.title}}</el-radio>
                                </el-radio-group>
                            </div>
                        </div>

                        <div class="item">
                            <label for="" class="label">政策有效日期：</label>
                            <div class="formControl">
                                <el-date-picker size="mini" v-model="policy_date" type="daterange" range-separator="至" disabled start-placeholder="开始日期"
                                                end-placeholder="结束日期"></el-date-picker>
                            </div>
                        </div>
                        <div class="item" style="align-items: flex-start;">
                            <label for="" class="label">附件上传：</label>
                            <div class="formControl">
                                <el-upload class="upload" :limit="100" disabled drag :action="action" accept=".doc,.docx,.pdf,.excel,.ppt" :on-remove="handleRemove"
                                           :on-change="handleOnChange" :file-list="fileList">
<!--                                    <i class="el-icon-upload fijian-icon-upload"></i>-->
<!--                                    <div class="el-upload__text" style="font-size: 14px;">-->
<!--                                        点击或将文件拖拽到这里上传-->
<!--                                    </div>-->
<!--                                    <div class="el-upload__tip" slot="tip">支持扩展名：.doc .docx .pdf .excel .ppt</div>-->
                                </el-upload>
                            </div>
                        </div>
                    </el-col>
                </el-row>
            </div>
        </el-dialog>
    </el-container>
</template>
<script>
    import Editor from '@/components/Editor';
    import qs from 'qs';
    import Pages from "@/components/PageComponents"; //分页
    import {
        success,
        error,
        warning
    } from "@/utils/notification";
    export default {
        data(){
            return{
                meta:{},
                page:1,
                limit:15,
                project_id:0,
                tableData:[
                ],
                policy_proName:'',
                detailsVisible:false,
                // 下面是详情页所需数据
                title: '', //标题
                description: '', //描述
                publisher_full_name: '', //称全成
                publisher_simple_name: '', //简称
                apply_to_area_type: 1, //地域:1全国,2其他省市,3园区
                apply_to_area_type_array: [], //apply_to_area_type:省市区数组，3:园区数组，1:不传
                scale_id: '', //规模编号
                scale_industry_id: '', //行业编号
                scale_engage_number: '', //从业人数
                scale_revenue: '', //	营业收入
                total_assets: '', //资产总额
                types: [], //类型
                article_link_url: '', //文章类型链接地址，为空则表示本地输入，否则为外链
                content: '', //富文本
                img: '',
                industry_scope: 1, //1创投2国民经济
                industry_scope_array: '', //industry_scope： 1:创投数组，2:国民经济数组
                systems: [], //体系
                policy_status: 1, //政策状态 1即将生效2生效中3已过期4延长期
                policy_date: '',
                attachments: [], //附件

                areaType: [{
                    id: 1,
                    title: '全国'
                },
                    {
                        id: 2,
                        title: '其他省市'
                    },
                    {
                        id: 3,
                        title: '园区'
                    }
                ],
                industryScopeType: [{
                    id: 1,
                    title: '创投行业分类'
                },
                    {
                        id: 2,
                        title: '国民经济行业分类'
                    }
                ],
                policyStatusType: [{
                    id: 1,
                    title: '即将生效'
                },
                    {
                        id: 2,
                        title: '生效中'
                    },
                    {
                        id: 3,
                        title: '已过期'
                    },
                    {
                        id: 4,
                        title: '延长期'
                    }
                ],
                materialType: [],
                tixi: [],
                booksType: '自定义',
                booksTypeActive: false,
                fileList: [],
                guimo: [], //规模
                hangye: [], //行业
                congye: [], //从业人数
                yingye: [], //营业收入
                zichan: [], //资产总额
                lingyu: [], //领域
                action: this.URL.upLoad,
                id: '',
                cityID: '',
                area: [],
                yuanqu: [],
                areas: [],
                yq: [],
                props2: {
                    multiple: true,
                    value: 'id',
                    label: 'name'
                },
                props: {
                    multiple: true,
                    checkStrictly: true,
                    value: 'id',
                    label: 'name'
                },
                props3: {
                    multiple: true,
                    checkStrictly: true,
                    value: 'id',
                    label: 'title'
                }
            }
        },
        components:{ // Editor
            Pages,
        },
        created() {
            this.policy_proName = sessionStorage.policy_proName
            // this.getTabledata(10220)
            if(this.$route.query.id){
                this.project_id = this.$route.query.id
                // this.project_id = 10220
                // this.getTabledata(10220)
                this.getTabledata(this.$route.query.id)
            }
        },
        mounted() {

        },
        methods: {
            pageChange(val) {
                this.page = val
                this.getTabledata(this.project_id)
            },
            getTabledata(id){ // 获取列表数据
                this.$http.get(this.URL.adminUrl.policy_pro_policylist,{params:{
                        project_id:id,
                        limit:15,
                        page:this.page
                    }}).then(res=>{
                        // console.log(res,'zhengce')
                    this.tableData = res.data.data.data
                    this.meta = res.data.data.meta
                })
                .catch(err=>{
                    error('请求数据失败，请联系管理员')
                    console.log(err)
                    })
            },
            goBack(){
                this.$router.go(-1)
                sessionStorage.backName = 'showPro'
            },
            showDetails(row){
                this.detailsVisible = true
                this.id = row.id
                // this.id = 259
                var url = '';
                this.getGuimoData();
                this.getHangyeData();
                this.getCongyeData();
                this.getyingyeData();
                this.getZichanData();
                this.getLingyuData(this.URL.adminUrl.starts);
                this.getTixiData();
                if (JSON.parse(localStorage.getItem("area"))) {
                    this.area = JSON.parse(localStorage.getItem("area"));
                } else {
                    this.getgetArea();
                }

                this.getgetOrgins()
                this.getMaterialTypeData();
                if (typeof this.id != 'undefined') {
                    this.booksTypeActive = true;
                    this.getdetailsData()
                }
            },
            // 政策详情接口 需要更改哟，复用过来的
            //获取政策-详情数据
            getdetailsData() {
                this.$http.get(this.URL.adminUrl.delPolicyArticle + this.id).then((res) => {
                    let result = res.data.data
                    this.title = result.title
                    this.description = result.description
                    this.publisher_full_name = result.publisher_full_name
                    this.publisher_simple_name = result.publisher_simple_name
                    this.apply_to_area_type = result.apply_to_area_type
                    if (result.apply_to_area_type == 2) {
                        let arr3 = [],
                            arr4 = [];
                        result.areaOrorgan.map(item => {
                            arr4 = []
                            item.map(item2 => {
                                if(arr4.length){
                                    arr4.push(item2.id)
                                }else{
                                    let len = arr4.indexOf(item2.id);
                                    if(len>-1){
                                        arr4.splice(len,1);
                                    }else{
                                        arr4.push(item2.id);
                                    }
                                }
                            })
                            arr3.push(arr4)
                        })
                        this.areas = arr3;
                    } else if (result.apply_to_area_type == 3) {
                        let arr5 = [],
                            arr6=[];
                        result.areaOrorgan.map(item => {
                            arr6=[];
                            arr6.push(item.id);
                            arr5.push(arr6)
                        })
                        this.yq=arr5;
                    }


                    this.scale_id = result.scale_id
                    this.scale_industry_id = result.scale_industry_id
                    this.scale_engage_number = result.scale_engage_number
                    this.scale_revenue = result.scale_revenue
                    this.total_assets = result.total_assets
                    result.policy_types.forEach((item, index) => {
                        this.types.push(item.id)
                    })
                    this.content = result.content
                    this.article_link_url = result.article_link_url
                    if (result.article_link_url == null) {
                        this.booksType = '自定义';
                        this.changeArtical();
                    }
                    if (result.content == '') {
                        this.booksType = '插入链接';
                        this.changeArtical()
                    }

                    this.img = result.img
                    this.industry_scope = result.industry_scope
                    this.industryScopeChange();
                    let arr = [],
                        arr2 = [];
                    result.industryScope.map(item => {
                        arr2 = []
                        item.map(item2 => {
                            if(item2.length){
                                arr2.push(item2.id)
                            }else{
                                let len = arr2.indexOf(arr2.id);
                                if(len>-1){
                                    arr2.splice(len,1)
                                }else{
                                    arr2.push(item2.id)
                                }
                            }

                        })
                        arr.push(arr2);
                    });

                    this.industry_scope_array = arr;

                    result.systems.forEach((item, index) => {
                        this.systems.push(item.id)
                    })
                    this.policy_status = result.policy_status
                    this.policy_date = [new Date(result.policy_start_date), new Date(result.policy_end_date)];
                    result.attachments.forEach((item, index) => {
                        this.fileList.push({
                            "name": item.name,
                            "url": item.url,
                            "ext": item.ext
                        })
                    })
                })
            },
            //获取地区
            getgetArea() {
                this.$http.get(this.URL.adminUrl.areaTree).then((res) => {
                    localStorage.setItem('area', JSON.stringify(res.data.data));
                })
            },
            //获取园区
            getgetOrgins() {
                this.$http.get(this.URL.adminUrl.search_organs).then((res) => {
                    this.yuanqu = res.data.data
                })
            },
            diyuChange() {
                this.apply_to_area_type_array = [];
            },
            //获取规模数据
            getGuimoData() {
                this.$http.get(this.URL.adminUrl.policy).then((res) => {
                    this.guimo = res.data.data;
                })
            },
            //获取行业数据
            getHangyeData() {
                this.$http.get(this.URL.adminUrl.industry).then((res) => {
                    this.hangye = res.data.data;
                })
            },
            //获取从业人数数据
            getCongyeData() {
                this.$http.get(this.URL.adminUrl.engages).then((res) => {
                    this.congye = res.data.data;
                })
            },
            //获取营业收入数据
            getyingyeData() {
                this.$http.get(this.URL.adminUrl.revenues).then((res) => {
                    this.yingye = res.data.data;
                })
            },
            //获取营业收入数据
            getZichanData() {
                this.$http.get(this.URL.adminUrl.funds).then((res) => {
                    this.zichan = res.data.data;
                })
            },
            //获取领域数据
            getLingyuData(url) {
                this.$http.get(url).then((res) => {
                    this.lingyu = res.data.data;
                })
            },
            //获取体系数据
            getTixiData() {
                this.$http.get(this.URL.adminUrl.systems).then((res) => {
                    this.tixi = res.data.data;
                })
            },
            getMaterialTypeData() {
                this.$http.get(this.URL.adminUrl.types).then((res) => {
                    this.materialType = res.data.data;
                })
            },
            //获取类型数据
            industryScopeChange() {
                this.industry_scope_array = [];
                if (this.industry_scope == 1) {
                    this.getLingyuData(this.URL.adminUrl.starts)
                } else {
                    this.getLingyuData(this.URL.adminUrl.economics)
                }
            },

            /* 自定义类型 */
            customType() {
                this.$prompt('类型', {
                    showClose: false
                }).then(({
                             value
                         }) => {
                    var data = {
                        title: value,
                        organ_id: sessionStorage.organizationId
                    }
                    this.$http.post(this.URL.adminUrl.type, data).then((res) => {
                        this.materialType.push(res.data.data);
                    })

                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '取消自定义'
                    });
                });

            },
            /* 自定义体系 */
            customSystems() {
                this.$prompt('体系', {
                    showClose: false
                }).then(({
                             value
                         }) => {
                    var data = {
                        title: value,
                        organ_id: sessionStorage.organizationId
                    }
                    this.$http.post(this.URL.adminUrl.system, data).then((res) => {
                        this.tixi.push(res.data.data);
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '取消自定义'
                    });
                });
            },
            changeArtical() {
                if (this.booksType == '自定义') {
                    this.article_link_url = '';
                    this.booksTypeActive = false
                } else {
                    this.content = '';
                    this.booksTypeActive = true
                }
            },
            /* 拿到富文本内容  */
            getEditorContent(data) {
                this.content = data;
            },
            // 上传主图之前
            handleChange(file) {
                if (file.size > (1024 * 1024 * 4)) {
                    this.$message.error('图片不能大于4M');
                    return
                }
            },
            //主图上传成功
            handleAvatarSuccess(res, file) {
                this.img = res.data.path;
            },
            //移除附件
            handleRemove(file, fileList) {
                this.attachments = [];
                this.fileList = fileList;
            },
            //附件改变
            handleOnChange(file, fileList) {
                this.attachments = [];
                this.fileList = fileList;
            },
            changeTime(time) {
                if (typeof time === 'undefined') {
                    return ''
                } else {
                    var d = new Date(time);
                    var datetime = d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate();
                    return datetime
                }
            },
            fabu() {
                this.fileList.forEach((item, index) => {
                    this.attachments.push({
                        "name": item.name,
                        "url": item.url,
                        "ext": item.ext
                    })
                });

                if (this.title == '') {
                    this.$message.error('标题不能为空！');
                    return
                }

                if (this.description == '') {
                    this.$message.error('描述不能为空！');
                    return
                }

                if (this.publisher_full_name == '' && this.publisher_simple_name == '') {
                    this.$message.error('全称和简称必须填其一！');
                    return
                }

                if (this.apply_to_area_type == '1') {
                    this.apply_to_area_type_array = [];
                } else if (this.apply_to_area_type == '2') {
                    this.apply_to_area_type_array = [];
                    if(this.areas.length==0){
                        this.$message.error('其他省市不能为空！');
                        return
                    }
                    this.areas.forEach((item, index) => {
                        this.apply_to_area_type_array.push(item[item.length - 1]);
                    });
                } else {
                    this.apply_to_area_type_array = [];
                    if(this.yq.length==0){
                        this.$message.error('园区不能为空！');
                        return
                    }
                    this.yq.forEach((item, index) => {
                        this.apply_to_area_type_array.push(item[item.length - 1]);
                    })
                }

                if (this.scale_id == '') {
                    this.$message.error('规模编号不能为空！');
                    return
                }
                if (this.scale_industry_id == '') {
                    this.$message.error('行业编号不能为空！');
                    return
                }
                if (this.scale_engage_number == '') {
                    this.$message.error('从业人数不能为空！');
                    return
                }
                if (this.scale_revenue == '') {
                    this.$message.error('营业收入不能为空！');
                    return
                }
                if (this.total_assets == '') {
                    this.$message.error('资产总额不能为空！');
                    return
                }
                if (this.types.length == 0) {
                    this.$message.error('类型不能为空！');
                    return
                }
                if (this.article_link_url == '' && this.content == '') {
                    this.$message.error('文章类型不能为空！');
                    return
                }
                if (this.img == '') {
                    this.$message.error('主图不能为空！');
                    return
                }
                let industryScopeArray = [];
                if(this.industry_scope_array.length == 0){
                    this.$message.error('领域(行业)不能为空！');
                    return
                }
                this.industry_scope_array.forEach((item, index) => {
                    industryScopeArray.push(item[item.length - 1]);
                })
                if (this.systems.length == 0) {
                    this.$message.error('体系不能为空！');
                    return
                }

                let id = sessionStorage.organizationId;
                var data = {
                    organ_id: id,
                    title: this.title, //标题
                    description: this.description, //描述
                    publisher_full_name: this.publisher_full_name, //全称
                    publisher_simple_name: this.publisher_simple_name, //简称
                    apply_to_area_type: this.apply_to_area_type, //地域:1全国,2其他省市,3园区
                    apply_to_area_type_array: this.apply_to_area_type_array, //2省市区数组，3:园区数组，1:不传
                    scale_id: this.scale_id, //规模编号
                    scale_industry_id: this.scale_industry_id, //行业编号
                    scale_engage_number: this.scale_engage_number, //从业人数
                    scale_revenue: this.scale_revenue, //	营业收入
                    total_assets: this.total_assets, //资产总额
                    types: this.types, //类型
                    article_link_url: this.article_link_url, //文章类型链接地址，为空则表示本地输入，否则为外链
                    content: this.content, //富文本
                    img: this.img, //主图
                    industry_scope: this.industry_scope, //1创投2国民经济
                    industry_scope_array: industryScopeArray, //industry_scope： 1:创投数组，2:国民经济数组
                    systems: this.systems, //体系
                    policy_status: this.policy_status, //政策状态 1即将生效2生效中3已过期4延长期
                    policy_start_date: this.changeTime(this.policy_date[0]), //	政策开始时间
                    policy_end_date: this.changeTime(this.policy_date[1]), //政策结束时间
                    attachments: this.attachments, //附件
                }
                if (typeof this.id != 'undefined') {
                    this.$http.put(this.URL.adminUrl.delPolicyArticle + this.id, data).then((res) => {
                        this.$alert('修改成功', {
                            callback: () => {
                                this.$router.push('/policyCompilation');
                            }
                        });
                    })
                } else {
                    this.$http.post(this.URL.adminUrl.addMaterial, data).then((res) => {
                        this.$alert('上传成功', {
                            callback: () => {
                                this.$router.push('/policyCompilation');
                            }
                        });
                    })
                }
            }
        }
    }
</script>
<style scoped>
    .go_back {
        height: 40px !important;
    }
    .page_title1{
        text-align: center;
        font-size: 28px;
        font-weight: bold;
        color:#333;
    }
    .page_title2{
        margin-top:20px;
        text-align: center;
        font-size: 24px;
        font-weight: 500;
    }
    .policyCompilationTitle {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        font-size: 18px;
        height: 40px;
    }

    .navbar {
        margin-bottom: 30px;
        padding: 15px;
        border-bottom: 1px solid #ccc
    }

    .item {
        display: flex;
        align-items: center;
        margin-bottom: 15px;
    }

    .label {
        width: 135px;
        text-align: right;
        padding-right: 8px;
        font-size: 14px;
    }

    .itemBorder {
        border: 1px solid #ccc;
        padding: 5px;
        border-radius: 5px;
    }

    .controls {
        display: flex;
        align-items: center;
        margin: 8px 0;
        padding-right: 15px;
    }

    .formControl {
        width: 100%;
    }

    .controlslabel {
        width: 75px;
        text-align: right;
        font-size: 14px;
    }

    .guimo .el-input__inner {
        width: 240px !important;
    }

    .avatar-uploader {
        border: 1px solid #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        font-size: 14px;
    }

    .noAvatar {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100px;
        height: 100px;
    }


    .avatar {
        width: 100px;
        height: 100px;
        vertical-align: middle;
    }

    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
    }

    .avatar-uploader:hover {
        color: #45d298;
        border: 1px solid #45d298;
        background-color: #ffffff;
    }

    .avatar-uploader:hover .avatar-uploader-icon {
        color: #45d298;
    }

    .required:before {
        content: '*';
        color: red;
        font-size: 16px;
        margin-right: 2px;
    }

    .el-upload__tip {
        color: red;
    }

    .btn-primary {
        background-color: #45d298;
        color: #ffffff;
        border: none;
    }

    .yinyeshouru:after {
        content: '万元';
        position: absolute;
        width: 40px;
        line-height: 28px;
        padding-left: 5px;
    }

    .fijian-icon-upload {
        color: #409eff;
        font-size: 67px;
        margin: 40px 0 16px;
        line-height: 50px;
    }

    .el-input-group__append {
        padding: 0 5px !important;
    }

    .el-cascader--mini {
        width: 100%;
    }
</style>
<style lang="less">
    .jumpPolicy {
        .el-tag {
            margin:3px;
        }
        .el-input.is-disabled .el-input__inner {
            background-color: #F5F7FA;
            color: #C0C4CC;
            cursor: not-allowed;
            border: none!important;
        }
        .el-input-group__append{
            border: none!important;
        }
        .el-checkbox__input.is-disabled+span.el-checkbox__label {
             color: #606266;
            cursor: not-allowed;
        }
        .el-upload-dragger {
            border: none!important;
            height:20px!important;
        }
        .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner {
            background-color:#409EFF;
            border-color: #409EFF;
        }
        .el-radio__input.is-disabled.is-checked .el-radio__inner {
            background-color:#409EFF;
            border-color: #409EFF;
        }
    }
</style>